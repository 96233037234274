import React, { useState , useEffect  } from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import RightNav from "../../components/right-nav";
import PageContent from "../../components/pagecontent";
import CategoryCards from "../../components/categorycards";
import Paginations from '../../components/pagination'

export const query = graphql`
  query Category($slug: String!) {
    articles: allStrapiPost(filter: { category: { slug: { eq: $slug } } }) {
      edges {
        node {
          slug
          title
          thumbnail
          category {
            name
            viewName
          }
          published_at
        }
      }
    }
    category: strapiCategory(slug: { eq: $slug }) {
      name
      viewName
    }
  }
`;

const Category = ({ data }) => {
  const articles = data;
  const category = data.category.name;
  const viewName = data.category.viewName;
  const seo = {
    metaTitle: viewName,
    metaDescription: `All ${category} posts`,
  };



  const [pageNo, setPageNo] = useState(1)

  //  hendle pagination 

  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);

  }

  useEffect(() => {
        window.scrollTo({top : 0 ,  behaviour: 'smooth ' })
}, [pageNo])


  // How many post yu want on every page 
  let PostPerPage = 15

  // pagination calculations
  let indexOfLastPost = pageNo * PostPerPage;
  let indexOfFirstPost = indexOfLastPost - PostPerPage;

  // slice post those are seen and those remain 
  let currentArticals = articles?.articles?.edges?.slice(indexOfFirstPost, indexOfLastPost);




  return (
    <div className="main_layout_container">
      <Layout records={articles}>
        <div className="main_container">
          <div className="page_category_main_div">
            <PageContent />
            <div className="category_title_container">
              <h2>{seo?.metaTitle}</h2>
            </div>
            <div>
              {/* category_cards_main_div */}
              <div className=" category_cards_main_div ">
                {/* card_div */}
                <div className="card_div ">
                  {currentArticals?.map((item) => (
                    <CategoryCards
                      thumbnail_title={item?.node?.title}
                      titleHeading={item?.node?.category?.name}
                      date={item?.node?.published_at}
                      slug={item?.node?.slug}
                      thumbnail={item?.node?.thumbnail}

                    />
                  ))}
                </div>
                {(articles === undefined ||
                  articles?.articles?.edges?.length === 0) && (
                    <div className="no_record_found_container">
                      <p>No Records Found for {seo?.metaTitle}</p>
                    </div>
                  )}
              </div>
              {articles?.articles?.edges?.length > 0 &&
                <div className="pagination__option">
                  <Paginations items={articles} pageNo={pageNo} handlePageChange={handlePageChange}  />
                </div>
              }
            </div>
          </div>
          <RightNav records={articles} />
        </div>
      </Layout>
    </div>
  );
};

export default Category;
